export const getImageFromBlogPost=(content, getBigImage) => {
    try {
      let regex = `(http)?s?:?(\/\/[^"']*\.(?:png|jpg|jpeg|gif|png|svg|tif|heic|webp|PNG|JPG|JPEG|GIF|PNG|SVG|TIF|HEIC|WEBP))`;
      let imageFound = content.match(regex);
      if (imageFound && imageFound.length > 0) {
        imageFound = imageFound[0];

        if (!getBigImage) {
          if (imageFound.indexOf("s1600") >= 0) {
            imageFound = imageFound.replace("s1600", "s640");
          }
        }

        if (getBigImage) {
          let imagesRatios = ["s640", "s320", "s200", "s400"];
          for (var i = 0; i < imagesRatios.length; i++) {
            if (imageFound.indexOf(imagesRatios[i]) >= 0) {
              imageFound = imageFound.replace(imagesRatios[i], "s1600");
              break;
            }
          }
        }
        return imageFound;
      } else {
        let img = this.getGoogleImageInPost(content);
        if (img) {
          return img;
        } else {
          let reg = /d\/(?:\w|['-]\w)+/g;
          let youtube = content.match(reg);
          if (youtube.length > 0) {
            let youtubeId = youtube[0].substring(2);
            return `https://img.youtube.com/vi/${youtubeId}/maxresdefault.jpg`;
          } else {
            return window.DEFAULT_IMAGE;
          }
        }
      }
    } catch (err) {
      return window.DEFAULT_IMAGE;
    }
  }
import Vue from 'vue'
import App from './App.vue'
import Autocomplete from '@trevoreyre/autocomplete-vue'
import VueContentPlaceholders from 'vue-content-placeholders'
import '@trevoreyre/autocomplete-vue/dist/style.css'


let app = document.getElementById('app')
let props = {
  url: app.getAttribute('data-url'),
  name: app.getAttribute('data-name'),
  map: app.getAttribute('data-map'),
  searchState: app.getAttribute('data-type'),
  companyLabel: app.getAttribute('data-company-label'),
}

Vue.prototype.zoneAttributes = props
Vue.config.productionTip = false

const detectImageError = {
  install(Vue, options) {
    Vue.prototype.detectImageError = (e) => {
      e.target.src = window.defaultImage || 'https://uptodatewebdesign.s3.eu-west-3.amazonaws.com/uploads/photos-1618802110694.png';
    }
  },
}
Vue.use(detectImageError)
Vue.use(Autocomplete)
Vue.use(VueContentPlaceholders)

new Vue({
  render: h => h(App),
}).$mount('#app')

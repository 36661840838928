<template>
  <div id="app">
    <p>
      {{ formattedBody }}
      <span class="cursor" href="#" @click="showingFullText = !showingFullText">
        Read {{ showingFullText ? "Less" : "More" }}
      </span>
    </p>
  </div>
</template>

<script>
export default {
  props: ["content"],
  data() {
    return {
      showingFullText: false,
      body: ''
    };
  },
  created(){
      if (this.content) {
          this.body = this.content
      }
  },

  computed: {
    formattedBody() {
      if (this.showingFullText) {
        return this.body;
      }

      return `${this.body.slice(0, 200).trim()}...`;
    },
  },
};
</script>

<style>
    .cursor {
        cursor: pointer;
    }
</style>